





















































































import { Component, Prop, Emit, Vue, Watch } from 'vue-property-decorator'
import {mapState} from 'vuex';
import {STORE_KEY} from '@/modules/CustomerList/_store';
import { filterFields } from './constants'


interface Filters extends Record<string, undefined | null | string | boolean> {
  age_from: null | string
  age_to: null | string
  date_enter_from: null | boolean
  date_enter_to: null | boolean
  sex: null | boolean
}

const emptyFilters = {
  age_from: 18,
  age_to: 99,
  date_enter_from: null,
  date_enter_to: null,
  sex: null,
}


@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'cities',
    ]),
  },
})
export default class ModalFilter extends Vue {
  @Prop(Boolean) public readonly show: boolean | undefined

  public display: boolean = false

  public sexs: any[] = [
    { text: 'мужской', value: 'm' },
    { text: 'женский', value: 'f' },
    { text: 'не важно', value: null },
  ]

  public types: any[] = [
    { text: 'все', value: null },
    { text: 'списание', value: 'write-off' },
    { text: 'начисление', value: 'accural' },
  ]

  public filters: Filters = {} as Filters


  // ---------------------------------------------------------------------------
  @Watch('show')
  public onOpened(nv: boolean) {
    if (nv) {
      filterFields.forEach((key: string) => {
        const value = this.$route.query[key]

        if (key === 'sex') {
          this.$set(this.filters, key, value)
        }
        else if (['date_enter_from', 'date_enter_to'].includes(key) && !!value) {
          this.$set(this.filters, key, value)
        }
        else if (['age_from', 'age_to'].includes(key)) {
          if (!!value) {
            // @ts-ignore
            this.$set(this.filters, key, parseInt(value))
          }
          else {
            this.$set(this.filters, 'age_from', 18)
            this.$set(this.filters, 'age_to', 99)
          }
        }
        else if (key === 'cities' && !!value) {
          const cities = JSON.parse(value as string)
          // @ts-ignore
          const cities_choice = this.cities.filter((city) => cities.includes(city.id as string) ?? city )
          this.$set(this.filters, key, cities_choice)
        }
        else {
          this.$set(this.filters, key, null)
        }
      })

      this.display = true
    }
  }

  public onChangeValue(key: string, value: undefined | null | string | boolean) {
    this.$set(this.filters, key, value)
  }

  public setFilter() {
    this._onCloseEmit(true)
  }

  public resetFilter() {
    // @ts-ignore
    this.filters = { ...emptyFilters }

    this._onCloseEmit(true)
  }

  public onClose() {
    this._onCloseEmit()
  }

  @Emit('close')
  private _onCloseEmit(emit = false) {
    this.$bvModal.hide('modal-center')

    if (emit) {
      return this.filters
    }
  }


}
