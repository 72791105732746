import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import { searchFields } from '@/modules/CustomerList/constants';

interface CustomerListState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  filter: any;

  lastMeta: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };

  documents: any[];
  cities: any[];
}

const $state: CustomerListState = {
  loading: false,
  loaded: false,
  fatalError: false,

  filter: {
    cities: [],
    date_enter_from: null,
    date_enter_to: null,
    sex: null,
    age_from: 18,
    age_to: 99,
  },
  lastMeta: {
    page: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },

  documents: [],
  cities: [],
}

export const $actions: ActionTree<CustomerListState, RootState> = {
  loadData({ commit, getters, dispatch }, query) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      const params: any = {}

      params.page = query.page || 1


      Object.entries(query)
        .forEach(([key, value]: [string, any]) => {
          if (searchFields.includes(key)) {
            params[key] = value
          }
        })

      params.cities = query.cities ? query.cities : null
      params.age_from = query.age_from ? query.age_from : null
      params.age_to = query.age_to ? query.age_to : null
      params.date_enter_from = query.date_enter_from ? query.date_enter_from : null
      params.date_enter_to = query.date_enter_to ? query.date_enter_to : null
      params.sex = query.sex ? query.sex : null

      commit('START_LOADING')
      dispatch('preloadData').then(() => {

        vm.$http
          .get('/admins/rest/v1/customers', {params})
          .then((response: AxiosResponse) => {
            commit('SET_ITEMS_LIST', response.data)
            commit('LOADED_SUCCESS')

            resolve(null)
          })
          .catch((error: any) => {
            // tslint:disable-next-line:no-console
            console.error('Fatal error', error)
            commit('LOADED_ERROR')
          })
      })
    })
  },
  preloadData({ commit, state }) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/cities`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_CITIES', response.data)
          resolve(true)
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },
}

export const $mutations: MutationTree<CustomerListState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },

  SET_ITEMS_LIST(state, data) {
    state.documents = data.customers

    state.lastMeta = {
      page: data.meta.page,
      pageSize: data.meta.per_page,
      totalCount: data.meta.total_count,
      totalPages: data.meta.total_pages,
    }
  },
  SET_CITIES: (state, { cities }) => {
    state.cities = cities
  },
}

export const $getters: GetterTree<CustomerListState, RootState> = {}

export const STORE_KEY = '$_customer_list'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
