export const searchFields: string[] = [
  'search_first_name',
  'search_last_name'
]

export const filterFields: string[] = [
  'age_from',
  'age_to',
  'date_enter_from',
  'date_enter_to',
  'sex',
  'cities',
]
